<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { computed, resolveComponent, useRoute } from '#imports'
  import GameHierarchyLabel from '~/components/GameHierarchyLabel/GameHierarchyLabel.vue'
  import { RouteRecordId } from '~/model/RouteRecord/RouteRecord'
  import { UiIconId, UiIconSizeId } from '~/model/UiIcon/UiIcon'
  import useTranslation from '~/composables/Translation/Translation'
  import { useGameBreadcrumbStore } from '~/store/GameBreadcrumb'
  import useAudioController from '~/composables/AudioController/AudioController'
  import { GameHierarchyId } from '~/model/GameHierarchy/GameHierarchy'
  import { BtnPlayToggleFacet } from '~/components/BtnPlayToggle/BtnPlayToggleFacet'

  const gameBreadcrumbStore = useGameBreadcrumbStore()

  const { activeHierarchyId } = storeToRefs(gameBreadcrumbStore)
  const route = useRoute()
  const { t } = useTranslation()
  const { allowAudio, playAudio, toggleAudio } = useAudioController()

  const SidebarContentCity = resolveComponent('SidebarContentCity') as string
  const SidebarContentDistrict = resolveComponent('SidebarContentDistrict') as string
  const SidebarContentCinemaSection = resolveComponent('SidebarContentCinemaSection') as string

  const contentElement = computed<string | undefined>(() => {
    switch (route.name) {
      case RouteRecordId.GameCity:
        return SidebarContentCity
      case RouteRecordId.GameDistrict:
        return SidebarContentDistrict
      case RouteRecordId.GameCinemaSection:
        return SidebarContentCinemaSection
    }
  })

  const isCinema = computed<boolean>(() => {
    return [
      RouteRecordId.GameCinemaRoom,
      RouteRecordId.GameCinemaSection,
      RouteRecordId.GameCinemaStorage,
    ].includes(route.name as RouteRecordId)
  })

  const isPlaying = computed<boolean>(() => {
    return allowAudio.value && playAudio.value
  })

  const onToggle = () => {
    allowAudio.value = !allowAudio.value
  }
</script>

<template>
  <div class="c-sidebar-shell s-container s-container--sidebar">
    <div class="c-sidebar-shell__container s-container__container">
      <div class="c-sidebar-shell__header">
        <template v-if="isCinema">
          <GameHierarchyLabel class="c-sidebar-shell__label">
            <template #icon>
              <UiIcon :id="UiIconId.IconCoreWhiteNegCinema" :size="UiIconSizeId.Large" />
            </template>
            <template #label>
              {{ t('game-hierarchy.cinema') }}
            </template>
          </GameHierarchyLabel>
        </template>
        <template v-else>
          <GameHierarchyLabel :hierarchy-id="activeHierarchyId" class="c-sidebar-shell__label" />
        </template>
        <div class="c-sidebar-shell__audio">
          <template v-if="activeHierarchyId === GameHierarchyId.District">
            <BtnPlayToggle
              :facets="[BtnPlayToggleFacet.InSidebar, BtnPlayToggleFacet.Light]"
              :is-playing="isPlaying"
              @click="onToggle"
            />
          </template>
        </div>
      </div>
      <div class="c-sidebar-shell__content">
        <component :is="contentElement" v-if="contentElement" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./_SidebarShell.scss"></style>
